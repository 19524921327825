/*♪┏(°.°)┛┗(°.°)┓♪ Colors ♪┏(°.°)┛┗(°.°)┓┗♪*/
$azure:                                       #009fe3;
$black:                                       #000000;
$black-two:                                   #282828;
$black-three:                                 #d1d1d1;
$dark-grey-blue:                              #28464b;
$greyish-brown:                               #4a4a4a;
$pea-green:                                   #68b514;
$scarlet:                                     #d0021b;
$warm-grey:                                   #9b9b9b;
$warm-grey-two:                               #979797;
$white:                                       #ffffff;
$white-two:                                   #d8d8d8;
$white-three:                                 #fafafa;
$white-four:                                  #ececec;
$warm-grey-three:                             #9a9a9a;

$bleu-psi:                                    #25487a;
$bleulight-psi:                               #009EE2;

$primary:       $bleu-psi !default;


